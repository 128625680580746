import React from "react";
import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/wso2/banner.jpg";
import ContrastSection from "../../../components/ContrastSection";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
} from "reactstrap";

//Logo
import Logo from "../../../images/partners/wso2.png";

//Icons
import iconBalance from "../../../images/service-icons/wso2/balance-scale.svg";
import iconExpertise from "../../../images/service-icons/wso2/expertise.svg";
import iconSupport from "../../../images/service-icons/wso2/support.svg";

//Images
import img1 from "../../../images/services/wso2/configuration.jpg";
import img2 from "../../../images/services/wso2/consultancy.jpeg";
import img3 from "../../../images/services/wso2/validation.png";
import img4 from "../../../images/services/wso2/team.jpg";
import img5 from "../../../images/services/wso2/pipeline.jpg";
import img6 from "../../../images/services/wso2/analytics.jpg";
import img7 from "../../../images/services/wso2/support.jpg";
import img8 from "../../../images/services/wso2/resource-aug.jpg";

//Clients
import MVola from '../../../images/services/logo/Logo-Mvola.png';
import Tigo from '../../../images/services/logo/logo-Tigo.png';
import Togocom from '../../../images/services/logo/Logo-Togocom.png';
import Stellarix from '../../../images/services/logo/Logo-Stellarix.png';
import Axian from '../../../images/services/logo/Logo-Axian.png';

//Contact
import Roshan from "../../../images/services/wso2/roshan2.png";
import Sylvia from "../../../images/services/qa/sylvia2.png";
import UserCard from "../../../components/UserCard";
import SvgIconsSet from "../../../components/SvgIconsSet";

const servicesList = [
  {
    id: "1",
    title: "WSO2 Product Installation & Configuration",
    textbold: "Ready to leverage WSO2? ",
    textdetail:
      "Our experts will handle the entire installation and configuration process for WSO2's core products, including API Manager, Micro Integrator, and Identity Server. We tailor configurations to your specific needs to ensure optimal performance.",
  },
  {
    id: "2",
    title: "Consultancy",
    textbold: "Is your WSO2 running optimally?",
    textdetail:
      "We offer in-depth consulting services to assess your existing WSO2 deployments. Our team will analyze your architecture, identify potential bottlenecks, and provide actionable recommendations for optimization and improvement.",
  },
  {
    id: "3",
    title: "WSO2 Platform Validation as an independent partner",
    textbold: "Confident in your WSO2 deployment?",
    textdetail:
      "As an independent partner, we will conduct thorough testing to validate your WSO2 platform. Our goal is to ensure that your implementation meets security, scalability, and performance requirements.",
  },
  {
    id: "4",
    title: "Performance Testing",
    textbold: "Can your WSO2 handle the load?",
    textdetail:
      "Our team will help you identify and address performance bottlenecks within your WSO2 integrations and APIs. Through comprehensive performance testing, we ensure that your WSO2 platform can efficiently handle your expected workload.",
  },
  {
    id: "5",
    title: "DevOps and CI/CD for Integrations and APIs",
    textbold: "Looking for faster and reliable deployments?",
    textdetail:
      "We streamline your integration and API development lifecycle by implementing DevOps practices and CI/CD pipelines. This facilitates smoother deployments, faster time-to-market, and greater agility.",
  },
  {
    id: "6",
    title: "Monitoring and Analytics",
    textbold: "Need real-time insights?",
    textdetail:
      "We assess your specific monitoring needs and design a customized ELK and Choreo configuration that captures the most relevant WSO2 data. Through informative dashboards within Kibana, you gain real-time insights into your WSO2 platform's performance, security, and overall health, enabling proactive problem identification and resolution.",
  },
  {
    id: "7",
    title: "Managed Services and 24/7 Support",
    textbold: "Need to focus on your business while we manage your WSO2 platform?",
    textdetail:
      "With our comprehensive managed services, you can rely on our experts to handle ongoing WSO2 platform management, including patching, updates, and troubleshooting. Additionally, we provide 24/7 support, ensuring your system is always running smoothly.",
  },
  {
    id: "8",
    title: "Resource Augmentation",
    textbold: "Need to strengthen your WSO2 team?",
    textdetail:
      "If you require additional expertise for your WSO2 project, we offer resource augmentation. Our team provides skilled WSO2 developers, testers, and enterprise-level project managers to augment your team and ensure successful project completion.",
  },
];

const WSO2Services = () => {
  return (
    <Layout bodyClass="service-page">
      <Seo
        title="WSO2 Integration Services for Enterprise Customers"
        description="WSO2 implementation, CI/CD, Analytics and Support"
      />

      <IntroBanner
        title="WSO2 Integration Services for Enterprises"
        subtitle="WSO2 implementation, CI/CD, analytics and support"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="WSO2 Partnership: Turbocharge Digital Transformation for Better Operations"
          para="We understand that successful digital transformation requires agility in technology. In this context, a robust middleware infrastructure to do the heavy lifting for your digital experiences (apps, workflows, and automations) is indispensable. WSO2, a 100% open standard and open source-based platform, is one such platform we can provision to handle all the technical complexities while you can focus on enhancing your business operations. With our expertise as a Value-Added Reseller partner, we offer a range of services and benefits catered specifically to enterprise customers using or intending to use the WSO2 platform."
        />
      </div>
      <section className="section-our-partners">
        <Container className="industry-section">
          <Row className="mt-4 industry-partners">
            <Col>
              <div className="container pb-6 section-2">
                <div className="row align-items-center">
                  <div className="col-md-6 pr-md-5 d-flex justify-content-center">
										<img
												alt="istqb logo"
												className="img-fluid w-50"
												src={Logo}
											/>
                  </div>
                  <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-end">
                    {/* <div className="title title-1">Industry Partners</div> */}
                    <p>
                    iTelaSoft, as a reseller and Integration Partner with WSO2, empowers and supports customers by seamlessly integrating cutting-edge WSO2 technologies into their systems, ensuring optimal performance and efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="">
          <div className="title title-1">Why Choose iTelaSoft as Your Value-Added Reseller Partner?</div>
          {/* <div className="sub-title">
            Choosing an independent quality engineering and validation partner
            such as iTelaSoft offers several advantages.{" "}
          </div> */}

          <Row className="mt-2">
            <Col>
              <div class="icon-with-text-new">
                <div className="icon-container"><img src={iconBalance} alt="Icon"/></div>
                <div class="text">
                  <strong>Flexible Licensing Options</strong>
                  <p>As a Value-Added Reseller partner, we provide flexible licensing options that suit your business requirements, ensuring you can optimize the benefits of WSO2.</p>
                </div>
              </div>
              <div class="icon-with-text-new">
                <div className="icon-container"><img src={iconExpertise} alt="Icon"/></div>
                <div class="text">
                <strong>Expertise & Experience</strong>
                <p>With our team of experts and numerous WSO2 implementations, we possess a deep understanding of the platform's various components. This allows us to offer accurate guidance on architecture, design, and implementation best practices, while avoiding potential pitfalls.</p>
                </div>
              </div>
              <div class="icon-with-text-new">
                <div className="icon-container"><img src={iconSupport} alt="Icon"/></div>
                <div class="text">
                <strong>Support & Maintenance</strong>
                <p>We provide extended ongoing support throughout the implementation and beyond. Our services encompass support for both WSO2 products and other systems in your business, ensuring uninterrupted operations.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section>
        <Container className="mt-5 wso2-why-sec">

          <Row>
            <Col md={6} lg={4} className="col-7 mb-5">
              <div>
                  <img
                    alt="WSO2-partner logo"
                    className=""
                    src={partner}
                  />
              </div>
            </Col>
          </Row>

          <div className="title title-1 mb-2">Why Choose iTelaSoft as Your Value-Added Reseller Partner?</div>

          <Row className="mt-4">
            <Col md={6} lg={3} className="mb-1">
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconFlexibility}
                  />
                  <CardTitle tag="h5">
                    Flexible Licensing Options{" "}
                  </CardTitle>
                  <CardText>
                    As a Value-Added Reseller partner, we provide flexible licensing options that suit your business requirements, ensuring you can optimize the benefits of WSO2.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={5} className="mb-1">
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconExpert}
                  />
                  <CardTitle tag="h5">
                    Expertise & Experience
                  </CardTitle>
                  <CardText>
                    With our team of experts and numerous WSO2 implementations, we possess a deep understanding of the platform's various components. This allows us to offer accurate guidance on architecture, design, and implementation best practices, while avoiding potential pitfalls.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={4} className="mb-1">
              <Card className="process-card">
                <CardBody>
                  <img
                    alt="Service Icon"
                    className="menu-item-icon"
                    src={iconSupport}
                  />
                  <CardTitle tag="h5">
                    Support & Maintenance
                  </CardTitle>
                  <CardText>
                    We provide extended ongoing support throughout the implementation and beyond. Our services encompass support for both WSO2 products and other systems in your business, ensuring uninterrupted operations.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section> */}

<section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1">Comprehensive WSO2 Service Offerings</div>
                {/* <div className="sub-title">
                iTelaSoft provides a wide range of Quality Engineering Services for different scenarios.{" "}
                </div> */}
              </div>
              <div className="mt-4">
								{servicesList.map((service) => {
                  return (
                    <div className="wrapper-service" key={service.id}>
                      <div className="service-details">
                          <div className="service-title">{service.title}
                          </div>
                        <div className="service-content">
                          <div className="service-breif pb-1">
                            {service.textbold}
                          </div>
                          <div className="service-description">
                            {service.textdetail}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section>
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1 mb-2">Comprehensive WSO2 Service Offerings</div>
              </div>
              <div className="mt-4">

                <div className="container section-2">
                  <div className="row">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img1}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        WSO2 Product Installation & Configuration 
                      </h2>
                      <p>
                        Our experts will handle the entire installation and configuration process for WSO2's core products, including API Manager, Micro Integrator, and Identity Server. We tailor configurations to your specific needs to ensure optimal performance.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img2}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Consultancy
                      </h2>
                      <p>
                        We offer in-depth consulting services to assess your existing WSO2 deployments. Our team will analyze your architecture, identify potential bottlenecks, and provide actionable recommendations for optimization and improvement.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img3}
                      />
                    </div>

                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">WSO2 Platform Validation as an independent partner</h2>
                      <p>
                        As an independent partner, we will conduct thorough testing to validate your WSO2 platform. Our goal is to ensure that your implementation meets security, scalability, and performance requirements.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img4}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Performance Testing 
                      </h2>
                      <p>
                        Our team will help you identify and address performance bottlenecks within your WSO2 integrations and APIs. Through comprehensive performance testing, we ensure that your WSO2 platform can efficiently handle your expected workload.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img5}
                      />
                    </div>

                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">DevOps and CI/CD for Integrations and APIs</h2>
                      <p>
                        We streamline your integration and API development lifecycle by implementing DevOps practices and CI/CD pipelines. This facilitates smoother deployments, faster time-to-market, and greater agility.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img6}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Monitoring and Analytics
                      </h2>
                      <p>
                        We assess your specific monitoring needs and design a customized ELK and Choreo configuration that captures the most relevant WSO2 data. Through informative dashboards within Kibana, you gain real-time insights into your WSO2 platform's performance, security, and overall health, enabling proactive problem identification and resolution.
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img7}
                      />
                    </div>

                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">Managed Services and 24/7 Support</h2>
                      <p>
                        With our comprehensive managed services, you can rely on our experts to handle ongoing WSO2 platform management, including patching, updates, and troubleshooting. Additionally, we provide 24/7 support, ensuring your system is always running smoothly. 
                      </p>
                    </div>
                  </div>
                  <div className="row py-6">
                    <div className="col-md-6 pr-md-5 order-md-2">
                      <img
                        alt="product innovation"
                        className="img-fluid"
                        src={img8}
                      />
                    </div>
                    <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
                      <h2 className="title-2">
                        Resource Augmentation
                      </h2>
                      <p>
                        If you require additional expertise for your WSO2 project, we offer resource augmentation. Our team provides skilled WSO2 developers, testers, and enterprise-level project managers to augment your team and ensure successful project completion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
            <div id="who-depend-on-us">
                {" "}
                <div className="title-1 mb-2">Who Depends on Us? </div>
                <div className="section-discription">
                    A number of large enterprises depended on iTelaSoft for our reliable and comprehensive WSO2 services.
                </div>
                <div className="section-our-partners-logo-set wso2-partners mb-4">
                    <a href="https://www.axian-group.com/en/homepage/" target="_blank">
                    <div className="wrap-logo p-0">
                        <div className="partners-logo">
                        {" "}
                        <img src={Axian} alt=""></img>
                        </div>
                        {/* <div className="logo-dec">Axian Group</div> */}
                    </div>
                    </a>
                    <a href="https://www.mvola.km/" target="_blank">
                    <div className="wrap-logo p-0">
                        <div className="partners-logo">
                        {" "}
                        <img src={MVola} alt=""></img>
                        </div>
                        {/* <div className="logo-dec">MVola</div> */}
                    </div>
                    </a>
                    <a href="https://www.tigo.co.tz/" target="_blank">
                    <div className="wrap-logo p-0">
                        <div className="partners-logo">
                        {" "}
                        <img src={Tigo} alt=""></img>
                        </div>
                        {/* <div className="logo-dec">Tigo Tanzania</div> */}
                    </div>
                    </a>
                    <a href="https://togocom.tg/" target="_blank">
                    <div className="wrap-logo p-0">
                        <div className="partners-logo">
                        {" "}
                        <img src={Togocom} alt=""></img>
                        </div>
                        {/* <div className="logo-dec">Togocom</div> */}
                    </div>
                    </a>
                    <a href="https://www.stellar-ix.com/en/" target="_blank">
                    <div className="wrap-logo p-0">
                        <div className="partners-logo">
                        {" "}
                        <img src={Stellarix} alt=""></img>
                        </div>
                        {/* <div className="logo-dec">Stellar-ix</div> */}
                    </div>
                    </a>
                </div>
                <div className="section-discription">
                    If you are an enterprise customer currently using or considering the adoption of the WSO2 platform, we are here to support you throughout your WSO2 journey.  
                    <br/>
                    Contact us today to learn more about how our services can benefit your business. 
                </div>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section>
        <div className="content">
          <HelpSection
            title="Let Us Know How to Help"
            para="We love to hear what challenges you go through in your business and discuss how we can assist you."
          />
        </div>
      </section> */}

      <section className="section-contact">
        <Container>
          <Row>
                <Col md={9} lg={9} xl={9} className="title-section flex-column">
                  <div className="title title-1 desk">
                    Let us know how to help 
                  </div>
                  <div className="title title-1 mobile">
                    Let us know how to help
                  </div>
                  <div>
                    <p>We love to hear what challenges you go through in your business and discuss how we can assist you.</p>
                  </div>
                  <div className="getting-touch-right">
                    <a className="btn btn-primary btn-icon-button" href="/contact/">
                        <span className="btn-text">Get in touch</span>
                        <span className="btn-icon">
                        <SvgIconsSet.SideArrow />
                        </span>
                    </a>
                  </div>
                </Col>
                <Col md={3} lg={3} xl={3} className="mb-2 contact-card-section col-6">
                    <a className="contact-card" href="/contact/">
                      <UserCard
                        name="Roshan Perera"
                        detail="Adviser"
                        image={Roshan}
                        link="https://www.linkedin.com/in/roshan-p-perera/"
                      />
                    </a>
                </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default WSO2Services;
